import React, { useContext, useEffect, useState } from 'react';
import '../../static/css/Login.css'; // Import the CSS file for the Login component
import { AuthContext } from '../../context/AuthContext';
import { RingLoader } from 'react-spinners'; // Import the ClipLoader from react-spinners
import { useLocation } from 'react-router-dom';
import config from '../../config';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie'; // Import js-cookie library
import { logInDevelopment } from '../../utils';

const Login = () => {
  const { isAuthenticated, userDetails, userFromApi } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [apiResponse, setApiResponse] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  let returnUrl = queryParams.get('returnUrl');

  if (returnUrl) {
    // Check if returnUrl is encoded
    if (returnUrl.includes('%')) {
      returnUrl = decodeURIComponent(returnUrl);
    }
    // Reconstruct returnUrl with the fragment if it's not included
    if (location.hash && !returnUrl.includes('#')) {
      returnUrl += location.hash;
    }
  }

  const ENCRYPTION_KEY = 'HareRamaHareKrishna'; // Replace with your actual key

  const encryptData = (data) => {
    return CryptoJS.AES.encrypt(data.toString(), ENCRYPTION_KEY).toString();
  };

  const decryptData = (data) => {
    const bytes = CryptoJS.AES.decrypt(data, ENCRYPTION_KEY);
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  useEffect(() => {
    const fetchApiData = async () => {
     // This is for if token exist in cookie
      /* 
      const azssCookie = Cookies.get('azss');

      if (azssCookie) {
        // Decrypt the token from the cookie
        const decryptedToken = decryptData(azssCookie);
        setApiResponse(decryptedToken);
        setLoading(false);
        return; // Skip the API call if the cookie is valid
      }
    */
      // If no valid cookie, proceed to call the API
      if (userFromApi !== null) {
        try {
          // Encrypt the userId
          const encryptedUserId = encryptData(userFromApi.document.name);

          // Call the desired API
          const response = await axios.post(`${config.ENDPOINT_API_URL}/3782873212983712891927127321`, {
            opm: encryptedUserId,
          });

          const usagesToken = response.data.usages;

          // Encrypt the token for storing in the cookie
          const encryptedToken = encryptData(usagesToken);

          // Set the cookie with a 48-hour expiration
          Cookies.set('azss', encryptedToken, { expires: 60 }); // Set token in cookie for 60 day

          setApiResponse(usagesToken);
        } catch (error) {
          console.error('Error calling API:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchApiData();
  }, [userFromApi]);

  useEffect(() => {
    if (apiResponse) {
      logInDevelopment('returnUrl:', returnUrl);
      const redirectUrl = `${config.DEVELOPER_PORTAL}/signin-sso?token=${apiResponse}&returnUrl=${encodeURIComponent(returnUrl)}`;
      window.location.href = redirectUrl;
    }
  }, [apiResponse, returnUrl]);

  logInDevelopment("isAuthenticated ", isAuthenticated);
  logInDevelopment("userDetails ", userDetails);
  logInDevelopment("userFromApi  ", userFromApi);
  logInDevelopment("API Response: ", apiResponse);

  if (loading) {
    return (
      <div className="payment-plan-wrapper">
        <div className="loader-container">
          <div className="loader-inner">
            <RingLoader color={"#049EFF"} size={100} margin={5}/>
            <p className="loader-text">Processing...</p>
          </div>
        </div>
      </div>  
    );
  }

  return (
    <div className="payment-plan-wrapper">
      <div className="loader-container">
        <div className="loader-inner">
          <RingLoader color={"#049EFF"} size={100} margin={5}/> 
          <p className="loader-text">Redirecting...</p>
        </div>
      </div>
    </div>    
  );
};

export default Login;
