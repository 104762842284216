import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { ClipLoader } from 'react-spinners'; // Import the ClipLoader from react-spinners
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { logInDevelopment } from '../../utils';
import { RingLoader } from 'react-spinners';
import config from '../../config';
import CryptoJS from 'crypto-js';


const Delegate = () => {
    const { isAuthenticated, userDetails, userFromApi } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    let returnUrl = queryParams.get('returnUrl');
    const productId = queryParams.get('productId');
    const userId = queryParams.get('userId');
    const operation = queryParams.get('operation');
    const salt = queryParams.get('salt');
    const subscriptionId = queryParams.get('subscriptionId');
    const mode = process.env.REACT_APP_STAGE || 'development'; // Set mode state
    const ENCRYPTION_KEY = 'HareRamaHareKrishna';



    const encryptData = (data) => {
        return CryptoJS.AES.encrypt(data.toString(), ENCRYPTION_KEY).toString();
    };

    if (returnUrl) {
        // Check if returnUrl is encoded
        if (returnUrl.includes('%')) {
            returnUrl = decodeURIComponent(returnUrl);
        }
        // Reconstruct returnUrl with the fragment if it's not included
        if (location.hash && !returnUrl.includes('#')) {
            returnUrl += location.hash;
        }
    }

    const handleOperation = async () => {
        try {
            switch (operation) {
                case 'Subscribe':
                    handleSubscribe();
                    break;
                case 'ChangeProfile':
                    handleChangeProfile();
                    break;
                case 'ChangePassword':
                    handleChangePassword();
                    break;
                case 'CloseAccount':
                    handleCloseAccount();
                    break;
                case 'Unsubscribe':
                    await handleUnsubscribe();
                    break;
                case 'SignIn':
                    await handleSignIn();
                    break;
                case 'SignUp':
                    await handleSigUp();
                    break;
                case 'SignOut':
                    handleSignOut();
                    break;
                default:
                    throw new Error('Invalid operation');
            }
        } catch (error) {
            console.error('Error handling operation:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSubscribe = () => {
        logInDevelopment('Handling Subscribe operation');
        // Redirect the user to the appropriate URL
        const redirectUrl = `${config.CONSOLE_API_URL}/?product=${productId}`;
        window.location.href = redirectUrl;
    };

    const handleChangeProfile = () => {
        logInDevelopment('Handling ChangeProfile operation');
        // Redirect the user to the personal info page
        window.location.href = `${config.COMMON_PORTAL}/personal-info`;
    };
    const handleChangePassword = () => {
        logInDevelopment('Handling ChangePassword operation');
        // Redirect the user to the personal info page
        window.location.href = `${config.COMMON_PORTAL}/personal-info`;
    };

    const handleCloseAccount = () => {
        logInDevelopment('Handling CloseAccount operation');
        // Redirect the user to the personal info page
        window.location.href = `${config.COMMON_PORTAL}/personal-info`;
    };

    const handleSignOut = () => {
        
        // window.location.href = 'https://accounts.appypie.com/logout?frompage=https:%2F%2Fwww.appypie.com%2Fendpoint%2F';
        // Redirect to the logout page and then to the apistst.appypie page
        window.location.href = `${config.DEVELOPER_PORTAL}`;
    };

    const handleUnsubscribe = async () => {
        logInDevelopment('Handling Unsubscribe operation');
        try {
            const response = await axios.post(`${config.ENDPOINT_API_URL}/getSubscription`, {
                sub: subscriptionId
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.data && response.data.productName) {
                // Extract productName from response
                const productName = response.data.productName;
                // Redirect the user to the appropriate URL
                const redirectUrl = `${config.CONSOLE_API_URL}/?product=${productName}`;
                window.location.href = redirectUrl;
            } else if (response.data && response.data.msg === 'Subscription not found') {
                // If subscription not found in our DB, remove from Azure
                const responseDel = await axios.post(`${config.ENDPOINT_API_URL}/938738732873273`, {
                    sub: subscriptionId
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                if (responseDel.data && responseDel.data.msg === 'success') {
                    const redirectUrlP = `${config.DEVELOPER_PORTAL}/profile`;
                    window.location.href = redirectUrlP;
                } else {
                    const redirectUrlP = `${config.DEVELOPER_PORTAL}/profile`; // Right now put the same, if needs to change in future 
                    window.location.href = redirectUrlP;
                }
            } else {
                console.error('Error fetching subscription: Missing productName in response');
                // Handle error appropriately
            }
        } catch (error) {
            console.error('Error calling getSubscription API:', error);
            // Handle error appropriately
        }
    };

    const handleSignIn = async () => {
        logInDevelopment('handling SignIn and Redirect operation');
        try {
            const response = await axios.post(`${config.ENDPOINT_API_URL}/3782873212983712891927127321`, {
                opm: encryptData(userFromApi.document.name)
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const sharedAccessToken = response.data.usages;
            //Redirect with login user on basis of azure sso token; 
            const redirectUrl = `${config.DEVELOPER_PORTAL}/signin-sso?token=${sharedAccessToken}&returnUrl=${encodeURIComponent(returnUrl)}`;
            window.location.href = redirectUrl;
        } catch (error) {
            console.error('Error fetching user by email:', error);
        }
    };

    const handleSigUp = async () => {
        logInDevelopment('handling SignUp and Redirect operation');
        const { email, first_name: firstName, last_name: lastName, c_id: userId } = userDetails;
        try {
            const response = await axios.post(`${config.ENDPOINT_API_URL}/getUser`, {
                email: email,
                firstName: firstName,
                lastName: lastName,
                userId: userId,
                portal: 'snappy'
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response && response.data) {
                //Redirect to signIn
                handleSignIn();
            }
        } catch (error) {
            console.error('Error fetching user by email:', error);
        }
    }

    useEffect(() => {
        handleOperation();
    },[userFromApi]);

    if (loading) {
        return (
            <div className="full-page-loader">
                <ClipLoader color={"#36d7b7"} size={150} />
                <p className="loader-text">Processing...</p>
            </div>
        );
    }

    return (
        <div className="main">
            <div className="container">
                <div className="loader-container">
                    <div className="loader-inner">
                        <RingLoader color={"#049EFF"} size={100} margin={5} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Delegate;
