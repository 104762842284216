import React, { useContext } from 'react';
//import '../static/css/Header.css'; // Import the CSS file for the header
//import '../static/css/Style.css';
import config from '../config';
import { AuthContext } from '../context/AuthContext';

const Header = () => {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <div className="site-header">
    <div className="container">
    <nav className="navbar navbar-expand-md navbar-light">
    <div className="container-fluid">
        <a className="navbar-brand" href="#">
          <img src={`${process.env.PUBLIC_URL}/appypie-endpoint.svg`} height="30" alt="AppyPie Endpoint Logo" />
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
              <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="collapsibleNavbar">
        <ul className="navbar-nav ms-auto" id="endpoint_top_menu">
        <li className="nav-item">
        <a className="nav-link" href="https://www.appypie.com/endpoint">Home</a>
        </li>
        {/*
        <li className="nav-item">
        <a className="nav-link" href="https://api-console.appypie.com/login?returnUrl=%2Fapis">APIs</a>
        </li>
        <li className="nav-item">
        <a className="nav-link" href="https://api-console.appypie.com/login?returnUrl=%2Fproducts">Products</a>
        </li>
        */}
        <li className="nav-item">
        <a className="nav-link" href="https://api-console.appypie.com/login?returnUrl=%2Freports">Reports</a>
        </li>
        {isAuthenticated ? (
          <li className="nav-item">
          <a className="nav-link" href="https://accounts.appypie.com/logout?frompage=https://www.appypie.com/endpoint/">Sign out</a>
          </li>
        ) : (
          <>
          <li className="nav-item">
            <a className="nav-link" href={`${config.DEVELOPER_PORTAL}/signin`}>Sign in</a>
            </li>
            <li className="nav-item">
            <a className="nav-link" href={`${config.DEVELOPER_PORTAL}/signup`}>Sign up</a>
            </li>
          </>
        )}
        </ul>
      </div>
      </div>
      </nav>
      </div>
      </div>
  );
};
export default Header;